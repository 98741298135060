// Actions

import { INDEX_TAB_FORM, INDEX_TAB_FORM_RESET } from '../../actions/ui/tabs';

export const initialTabsState = {
  duplicateCommunication: {
    index: 1,
  },
  communication: {
    index: 0,
  },
  workOrdersGeneral: {
    index: 0,
  },
  action: {
    index: 0,
  },
  surveillanceRoute: {
    index: 0,
  },
};

export const tabsType = {
  DUPLICATE_COMMUNICATION: 'duplicateCommunication',
  COMMUNICATION: 'communication',
  WORK_ORDERS_GENERAL: 'workOrdersGeneral',
  WORK_ORDERS_ACTION: 'workOrdersAction',
  SURVEILLANCE_ROUTE: 'surveillanceRoute',
  TRANSFER_SUBORDER: 'transferSuborder',
  EDIT_COMMUNICATION: 'editCommunication',
};

type actionType = {
  type: string;
  payload: { type: string; index: number };
};

export default function tabs(
  state = initialTabsState,
  { type, payload }: actionType
) {
  switch (type) {
    case INDEX_TAB_FORM:
      return {
        ...state,
        [payload.type]: {
          // eslint-disable-next-line
          // @ts-ignore
          ...state[payload.type],
          index: payload.index,
        },
      };
    case INDEX_TAB_FORM_RESET:
      return {
        ...state,
        [payload.type]: {
          // eslint-disable-next-line
          // @ts-ignore
          ...initialTabsState[payload.type],
        },
      };
    default:
      return state;
  }
}
