import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

// Utils
import { useCacheFormInSessionStorage } from '../../../hooks/useCacheForm';

// Components
import { VehiclesInvolvedTable } from '../../display/tables/formTables/VehiclesInvolvedTable';
import { Typography } from '../../display/Typography';
import { MainButton } from '../../inputs/buttons/MainButton';
import SimpleBackgroundSwitch from '../../inputs/buttons/SwitchButton/SimpleBackground';

// Styles
import {
  AdviceContainer,
  ButtonsContainer,
  Container,
  ContainerComponent,
  ContainerContent,
  ContainerTables,
  ContainerTitlePage,
  DamagesContainer,
  DamagesHeader,
  StyledInputTextArea,
  SwitchPaginationContainer,
  TableContainer,
  VehiclesContainer,
} from './styles';

// Types
import { Props } from './types';
import { yupResolver } from '@hookform/resolvers/yup';
import { DamagesSchema } from './schema';

const AddVehicleForm = ({
  onFormSubmit,
  namingPersistForm,
  initialData,
  onReturn,
}: Props) => {
  const [damagesSwitchState, setDamagesSwitchState] = useState<boolean>(true);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>({
    defaultValues: { ...initialData, intentVehicles: 'save-data' },
    resolver: yupResolver(DamagesSchema),
    context: { damagesSwitchState },
  });

  const [vehiclesSwitchState, setVehiclesSwitchState] = useState<boolean>(true);
  const [letters, setLetters] = useState(['A']);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const vehiclesValues = useWatch({ control });

  useLayoutEffect(() => {
    if (
      (initialData?.letters && typeof initialData.letters !== 'string') ||
      (initialData?.letters &&
        JSON.parse(initialData.letters as string) &&
        JSON.parse(initialData.letters as string).length > 0)
    ) {
      setValue('letters', initialData.letters);
      setLetters(
        typeof initialData.letters === 'string'
          ? JSON.parse(initialData.letters)
          : initialData.letters
      );
    } else {
      setValue('letters', JSON.stringify(letters));
    }

    if (initialData.involvedVehiclesSwitch) {
      setValue('involvedVehiclesSwitch', initialData.involvedVehiclesSwitch);
      setVehiclesSwitchState(JSON.parse(initialData.involvedVehiclesSwitch));
    } else {
      setValue('involvedVehiclesSwitch', 'true');
    }

    if (initialData.damagesSwitch) {
      setValue('damagesSwitch', initialData.damagesSwitch);
      setDamagesSwitchState(JSON.parse(initialData.damagesSwitch));
    } else {
      setValue('damagesSwitch', 'true');
    }
  }, []);

  const handleAddTable = useCallback(() => {
    const nextLetter = String.fromCharCode(
      letters[letters.length - 1].charCodeAt(0) + 1
    );
    setLetters((prevLetters) => {
      setValue('letters', JSON.stringify([...prevLetters, nextLetter]));
      return [...prevLetters, nextLetter];
    });
  }, [letters]);

  const handleRemoveTable = useCallback(
    (tableLetter: string) => {
      if (letters.length > 1) {
        const newArrayOfLetters = letters.filter(
          (letter) => letter != tableLetter
        );

        setLetters(newArrayOfLetters);
        setValue(`vehicles[${tableLetter}]`, undefined);
        setValue('letters', JSON.stringify(newArrayOfLetters));
      }

      return true;
    },
    [letters]
  );

  const handleChange = () => {
    if (vehiclesSwitchState) {
      setValue('involvedVehiclesSwitch', 'false');
    } else {
      setValue('involvedVehiclesSwitch', 'true');
    }

    setVehiclesSwitchState(!vehiclesSwitchState);
  };

  const handleDamagesChange = () => {
    if (damagesSwitchState) {
      setValue('damagesSwitch', 'false');
      setValue('damages', '');
    } else {
      setValue('damagesSwitch', 'true');
    }

    setDamagesSwitchState(!damagesSwitchState);
  };

  useCacheFormInSessionStorage(namingPersistForm, control);

  const checkAllTheCarDataValues = (carLetter: string) => {
    if (!vehiclesValues?.vehicles) {
      return false;
    }

    const carToCheck = vehiclesValues.vehicles[carLetter] || {};
    const isTrailerActive = carToCheck.isTrailerActive === 'true';

    let isTrailerFilled = true;

    if (isTrailerActive) {
      const { insurerTrailer, policyTrailer, registTrailer } = carToCheck;
      if (!insurerTrailer && !policyTrailer && !registTrailer) {
        isTrailerFilled = false;
      }
    }

    delete carToCheck.insurerTrailer;
    delete carToCheck.policyTrailer;
    delete carToCheck.registTrailer;
    delete carToCheck.isTrailerActive;
    delete carToCheck.damages;

    const carToCheckValues = Object.values(carToCheck);

    return (
      carToCheckValues.some((value) => value !== '' && value !== undefined) &&
      isTrailerFilled
    );
  };

  return (
    <Container
      onSubmit={(e) => {
        const allCarDataValid = letters.every(checkAllTheCarDataValues);
        if (allCarDataValid || !vehiclesSwitchState) {
          handleSubmit(onFormSubmit)();
        } else {
          e.preventDefault();
          setErrorMessage(
            'Si us plau, utilitzeu l’interruptor si no hi ha cotxes implicats.'
          );
        }
      }}
    >
      <DamagesContainer>
        <DamagesHeader>
          <Typography variant="semiBold">Danys</Typography>
          <SwitchPaginationContainer>
            <SimpleBackgroundSwitch
              changeState={handleDamagesChange}
              state={damagesSwitchState}
            />
            <Typography colorText="greySoftLight">3/3</Typography>
          </SwitchPaginationContainer>
        </DamagesHeader>
        {damagesSwitchState ? (
          <StyledInputTextArea
            disabled={false}
            {...register('damages', {})}
            name="damages"
            placeholder="Danys"
            borderError={!!errors.damages}
            error={errors.damages?.message as string}
          />
        ) : (
          <AdviceContainer>
            <Typography variant="semiBold" colorText="greyDark">
              No hi ha danys
            </Typography>
          </AdviceContainer>
        )}
      </DamagesContainer>

      <VehiclesContainer>
        <ContainerTitlePage>
          <Typography variant="semiBold">
            Afegeix els vehicles implicats
          </Typography>
          <SimpleBackgroundSwitch
            changeState={handleChange}
            state={vehiclesSwitchState}
          />
        </ContainerTitlePage>
        <ContainerContent>
          {vehiclesSwitchState ? (
            <TableContainer>
              <ContainerComponent>
                <ContainerTables>
                  {letters.map((letter) => (
                    <VehiclesInvolvedTable
                      namingPersistForm={namingPersistForm}
                      control={control}
                      key={letter}
                      register={register}
                      letter={letter}
                      letters={letters}
                      removeTable={() => handleRemoveTable(letter)}
                      setValue={setValue}
                    />
                  ))}
                </ContainerTables>
                <MainButton
                  text="+ Nou vehicle"
                  type="button"
                  onClick={handleAddTable}
                />
              </ContainerComponent>
              {errorMessage && (
                <Typography size="XS" colorText="lightCoralRed">
                  {errorMessage}
                </Typography>
              )}
            </TableContainer>
          ) : (
            <AdviceContainer>
              <Typography variant="semiBold" colorText="greyDark">
                No hi ha vehicles implicats
              </Typography>
            </AdviceContainer>
          )}
        </ContainerContent>
      </VehiclesContainer>

      <ButtonsContainer>
        <MainButton
          text="Tornar"
          type="button"
          variant="secondary"
          onClick={onReturn}
        />
        <MainButton text="Continuar" type="submit" />
      </ButtonsContainer>
    </Container>
  );
};

export default AddVehicleForm;
