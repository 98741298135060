import { useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import React, { useCallback, useLayoutEffect } from 'react';

// Components
import AddBasicInfoAccidentForm from '../../../../../components/forms/AddBasicInfoAccidentForm';

// StyledComponents
import { Container } from './styles';

// Constants
import { formAccidentNaming } from '../../../../../components/forms/AddBasicInfoAccidentForm/constants';

// Types
import { Accident } from '../../../../../types/accident';

//Redux
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function EditBasicInfoAccidentPage(): JSX.Element {
  const navigate = useNavigate();
  const loaderData = useAsyncValue() as Accident;
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (!loaderData) {
      navigate('..');
    } else {
      dispatch(
        coticOTSelected(
          'edit-accident',
          loaderData.orderParentCotic,
          loaderData.orderParentId
        )
      );
    }
  }, []);

  const handleReturn = useCallback(() => {
    navigate(-1);
  }, []);

  const onFormSubmit = () => {
    navigate('../damages-and-vehicles');
  };

  return (
    <Container>
      {loaderData && (
        <AddBasicInfoAccidentForm
          initialData={loaderData}
          onFormSubmit={onFormSubmit}
          namingPersistForm={formAccidentNaming.EDIT_BASIC_INFO_ACCIDENT_FORM}
          onReturn={handleReturn}
        />
      )}
    </Container>
  );
}
