import { LoaderFunction, defer } from 'react-router-dom';
import { AuthContextType } from '../../../../../hooks/useAuth';

// GQL
import { client } from '../../../../../gql/client';
import {
  FETCH_PREDEFINED_ORDERS,
  FETCH_SUB_CAPITOLS,
} from '../../../../../gql/queries/orders/predefinedData';
import { GET_SELECTORS_DATA } from '../../../../../gql/queries/orders/orders';
import {
  FETCH_CAPITOLS,
  FETCH_CLASSIFICATIONS,
} from '../../../../../gql/queries/orders/predefinedData';
import { GET_USER_BY_ID } from '../../../../../gql/queries/users';

// Sentry
import * as Sentry from '@sentry/react';

// Data static
import { groupLanes, groupTracks } from '../../../../../data/orders';

// Compression
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// naming
import { selectOrderOfAccidentById } from '../../../../../gql/selectors/accidents';
import { formAccidentNaming } from '../../../../../components/forms/AddBasicInfoAccidentForm/constants';

const loadAccidentSummary = async (idOrder: string) => {
  try {
    const workOrderData = await selectOrderOfAccidentById(idOrder);

    const operatorData = await client.query({
      query: GET_USER_BY_ID,
      variables: {
        input: {
          id: workOrderData?.userId,
        },
      },
    });

    return {
      ...workOrderData,
      operator: `${operatorData.data.user.name} ${
        operatorData.data.user.surName.split(' ')[0]
      }`,
    };
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work Order Summary loading error:', err);
    return true;
  }
};

const loadFormOptions = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      const [
        { data },
        {
          data: { capitols },
        },
        {
          data: { classifications },
        },
        {
          data: { predefinedOrders },
        },
        {
          data: { subCapitols },
        },
      ] = await Promise.all([
        client.query({
          query: GET_SELECTORS_DATA,
        }),
        client.query({
          query: FETCH_CAPITOLS,
        }),
        client.query({
          query: FETCH_CLASSIFICATIONS,
        }),
        client.query({
          query: FETCH_PREDEFINED_ORDERS,
        }),
        client.query({
          query: FETCH_SUB_CAPITOLS,
        }),
      ]);

      return {
        ...data,
        capitols,
        classifications,
        groupTracks,
        groupLanes,
        predefinedOrders,
        subCapitols,
      };
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work Order Summary loading error:', err);
    return true;
  }
};

export const EditAccidentWorkOrderLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined =
  (userData) =>
  async ({ params }) => {
    let dataFromAccidentWorkOrder = {};

    if (params.id) {
      dataFromAccidentWorkOrder = await loadAccidentSummary(params.id);
    }

    const stringifyForm = sessionStorage.getItem(
      formAccidentNaming.EDIT_ACCIDENT_FORM
    );

    const loaderToResolve = await loadFormOptions(userData);

    if (stringifyForm) {
      const sessionStorageData = JSON.parse(
        decompressSessionStorage(stringifyForm)
      );

      const combinedData = {
        ...loaderToResolve,
        ...dataFromAccidentWorkOrder,
        ...sessionStorageData,
      };

      return defer({
        data: combinedData,
      });
    }

    const combinedData = {
      ...loaderToResolve,
      ...dataFromAccidentWorkOrder,
      dataToCompare: dataFromAccidentWorkOrder,
    };
    return defer({
      data: combinedData,
    });
  };
