import { Reference, StoreObject } from '@apollo/client';
import { TypePolicies } from '@apollo/client/cache/inmemory/policies';
import { Role } from '../types/auth';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { CacheType } from '../types/cache';
import { findFirstCommonIndex, mergeArrays } from '../utils/mergeArrays';

// Redux
import { store } from '../state/configureStore';
import { getTake } from '../state/selectors/ui/tables';

export const policies: TypePolicies = {
  Query: {
    fields: {
      user: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'User',
            id: variables?.id,
          });
        },
      },
      team: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Team',
            id: variables?.id,
          });
        },
      },
      capitol: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Capitol',
            id: variables?.id,
          });
        },
      },
      subCapitol: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'SubCapitol',
            id: variables?.id,
          });
        },
      },
      classification: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Classification',
            id: variables?.id,
            coticId: variables?.coticId,
          });
        },
      },
      concession: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Concession',
            id: variables?.id,
          });
        },
      },
      mediaOfCommunication: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'MediaOfCommunication',
            id: variables?.id,
          });
        },
      },
      interlocutorGroup: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'InterlocutorGroup',
            id: variables?.id,
          });
        },
      },
      order: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Order',
            id: variables?.id,
          });
        },
      },

      orders: {
        keyArgs: [
          'input',
          [
            'type',
            'state',
            'fromDate',
            'toDate',
            'isMultimedia',
            'classification',
            'capitolId',
            'subCapitolId',
            'classification',
            'indexExpedition',
            'indexType',
            'roadId',
          ],
        ],
        merge(
          existing = {},
          variables,
          {
            args: {
              input: { cursor, take = existing?.orders?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return mergeCache(
            existing,
            CacheType.Orders,
            cursor,
            readField,
            take,
            variables
          );
        },
        read(
          existing,
          {
            args: {
              input: { cursor, take = existing?.orders?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return readCache(existing, CacheType.Orders, readField, cursor, take);
        },
      },

      communication: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Communication',
            id: variables?.id,
          });
        },
      },

      communications: {
        keyArgs: [
          'input',
          [
            'roadId',
            'concessionId',
            'fromDate',
            'toDate',
            'classification',
            'indexExpedition',
            'interlocutorGroupId',
            'indexType',
            'state',
          ],
        ],
        merge(
          existing = {},
          variables,
          {
            args: {
              input: { cursor, take = existing?.communications?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return mergeCache(
            existing,
            CacheType.Communications,
            cursor,
            readField,
            take,
            variables
          );
        },
        read(
          existing,
          {
            args: {
              input: { cursor, take = existing?.communications?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return readCache(
            existing,
            CacheType.Communications,
            readField,
            cursor,
            take
          );
        },
      },

      genericOrder: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'GenericOrder',
            id: variables?.id,
          });
        },
      },
      genericOrders: {
        keyArgs: [
          'input',
          [
            'capitolId',
            'subCapitolId',
            'concessionId',
            'fromDate',
            'toDate',
            'state',
            'classification',
            'indexExpedition',
            'indexType',
          ],
        ],
        merge(
          existing = {},
          variables,
          {
            args: {
              input: { cursor, take = existing?.genericOrders?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return mergeCache(
            existing,
            CacheType.GenericOrders,
            cursor,
            readField,
            take,
            variables
          );
        },
        read(
          existing,
          {
            args: {
              input: { cursor, take = existing?.genericOrders?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return readCache(
            existing,
            CacheType.GenericOrders,
            readField,
            cursor,
            take
          );
        },
      },

      campaign: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Campaign',
            id: variables?.id,
          });
        },
      },
      campaigns: {
        keyArgs: [
          'input',
          [
            'state',
            'concessionId',
            'fromDate',
            'toDate',
            'name',
            'classification',
            'indexExpedition',
            'indexType',
          ],
        ],
        merge(
          existing = {},
          variables,
          {
            args: {
              input: { cursor, take = existing?.campaigns?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return mergeCache(
            existing,
            CacheType.Campaigns,
            cursor,
            readField,
            take,
            variables
          );
        },
        read(
          existing,
          {
            args: {
              input: { cursor, take = existing?.campaigns?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return readCache(
            existing,
            CacheType.Campaigns,
            readField,
            cursor,
            take
          );
        },
      },

      accident: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Accident',
            id: variables?.id,
          });
        },
      },

      accidents: {
        keyArgs: [
          'input',
          [
            'weatherId',
            'concessionId',
            'fromDate',
            'toDate',
            'mediumKnowledgeId',
            'classification',
            'indexExpedition',
            'indexType',
            'state',
          ],
        ],
        merge(
          existing = {},
          variables,
          {
            args: {
              input: { cursor, take = existing?.accidents?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return mergeCache(
            existing,
            CacheType.Accidents,
            cursor,
            readField,
            take,
            variables
          );
        },
        read(
          existing,
          {
            args: {
              input: { cursor, take = existing?.accidents?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return readCache(
            existing,
            CacheType.Accidents,
            readField,
            cursor,
            take
          );
        },
      },

      action: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Action',
            id: variables?.id,
          });
        },
      },
      actions: {
        keyArgs: [
          'input',
          [
            'state',
            'concessionId',
            'fromDate',
            'toDate',
            'capitolId',
            'subCapitolId',
            'classification',
            'indexExpedition',
            'indexType',
          ],
        ],
        merge(
          existing = {},
          variables,
          {
            args: {
              input: { cursor, take = existing?.actions?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return mergeCache(
            existing,
            CacheType.Actions,
            cursor,
            readField,
            take,
            variables
          );
        },
        read(
          existing,
          {
            args: {
              input: { cursor, take = existing?.actions?.length },
            },
            readField,
          }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any
        ) {
          return readCache(
            existing,
            CacheType.Actions,
            readField,
            cursor,
            take
          );
        },
      },

      activity: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Activity',
            id: variables?.id,
          });
        },
      },
      geoPositionAction: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'GeoPositionAction',
            id: variables?.id,
          });
        },
      },
      geoPositionRoad: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'GeoPositionRoad',
            id: variables?.id,
          });
        },
      },
      asset: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Activity',
            id: variables?.id,
          });
        },
      },
      material: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Material',
            id: variables?.id,
          });
        },
      },
      attentionAccident: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'AttentionAccident',
            id: variables?.id,
          });
        },
      },
      materialUnit: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'MaterialUnit',
            id: variables?.id,
          });
        },
      },
      codification: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Codification',
            id: variables?.id,
          });
        },
      },
      subCodification: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'SubCodification',
            id: variables?.id,
          });
        },
      },
      measureType: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'MeasureType',
            id: variables?.id,
          });
        },
      },
      auxMachine: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'AuxMachine',
            id: variables?.id,
          });
        },
      },
      auxMachineUnit: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'AuxMachineUnit',
            id: variables?.id,
          });
        },
      },
      auxMachineType: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'AuxMachineType',
            id: variables?.id,
          });
        },
      },
      animal: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Animal',
            id: variables?.id,
          });
        },
      },

      animalUnit: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'AnimalUnit',
            id: variables?.id,
          });
        },
      },

      road: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Road',
            id: variables?.id,
          });
        },
      },

      vehicle: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Vehicle',
            id: variables?.id,
          });
        },
      },

      vehicleOccupant: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'VehicleOccupant',
            id: variables?.id,
          });
        },
      },

      vehicleCategory: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'VehicleCategory',
            id: variables?.id,
          });
        },
      },

      elementOrder: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'ElementOrder',
            id: variables?.id,
          });
        },
      },
      weather: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'Weather',
            id: variables?.id,
          });
        },
      },
      mediumKnowledge: {
        read(_, { toReference, variables }) {
          return toReference({
            __typename: 'MediumKnowledge',
            id: variables?.id,
          });
        },
      },

      readMediumKnowledge: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `MediumKnowledge:${id}`,
            })) ?? []
          );
        },
      },

      readCodification: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `Codification:${id}`,
            })) ?? []
          );
        },
      },
      readSubCodification: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `SubCodification:${id}`,
            })) ?? []
          );
        },
      },
      readMeasureType: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `MeasureType:${id}`,
            })) ?? []
          );
        },
      },
      readWeathers: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `Weather:${id}`,
            })) ?? []
          );
        },
      },

      readRoads: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `Road:${id}`,
            })) ?? []
          );
        },
      },

      readOrders: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `Order:${id}`,
            })) ?? []
          );
        },
      },

      readCommunications: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `Communication:${id}`,
            })) ?? []
          );
        },
      },

      readSubCapitols: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;

          return (
            variables?.ids?.map((id: string) => ({
              __ref: `SubCapitol:${id}`,
            })) ?? []
          );
        },
      },
      readOperators: {
        read(_, { readField }) {
          const users = readField('users');
          return ((users as []) ?? []).filter(
            (userRef: StoreObject | Reference | undefined) => {
              const userRoles = readField('roles', userRef);
              const teamIDs = readField('teamIDs', userRef);
              const concessionIDs = readField('concessionIDs', userRef);
              return (
                ((userRoles as string[]).includes(Role.OperatingTeam) ||
                  (userRoles as string[]).includes(Role.RoomTeam)) &&
                ((teamIDs as string[])?.length > 0 ||
                  (concessionIDs as string[])?.length > 0)
              );
            }
          );
        },
      },
      readOperatorsInternals: {
        read(_, { readField }) {
          return (readField('readOperators') as []).filter((operatorRef) =>
            readField('internal', operatorRef)
          );
        },
      },
      readActiveOperators: {
        read(_, { readField }) {
          return (readField('readOperators') as []).filter((operatorRef) =>
            readField('isActive', operatorRef)
          );
        },
      },
      readOperatorsExternals: {
        read(_, { readField }) {
          return (readField('readOperators') as []).filter(
            (operatorRef) => !readField('internal', operatorRef)
          );
        },
      },

      readVehicles: {
        read(_, { readField }) {
          const vehicles = readField('vehicles');
          return vehicles;
        },
      },

      readActiveVehicles: {
        read(_, { readField }) {
          return (readField('readVehicles') as []).filter((vehicleRef) =>
            readField('isActive', vehicleRef)
          );
        },
      },

      readVehicleCategories: {
        read(_, { readField }) {
          const vehicleCategories = readField('vehicleCategories');
          return vehicleCategories;
        },
      },

      readElementOrders: {
        read(_, options) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const variables: Record<string, any> | undefined = options.variables;
          return variables?.ids?.map((id: string) => ({
            __ref: `ElementOrder:${id}`,
          }));
        },
      },

      readUsers: {
        read(_, { readField }) {
          const users = readField('users');
          return users;
        },
      },

      readMaterials: {
        read(_, { readField }) {
          const materials = readField('materials');
          return materials;
        },
      },

      readAnimals: {
        read(_, { readField }) {
          const animals = readField('animals');
          return animals;
        },
      },

      readAuxMachines: {
        read(_, { readField }) {
          const auxMachines = readField('auxMachines');
          return auxMachines;
        },
      },
      readAuxMachinesTypes: {
        read(_, { readField }) {
          const auxMachineTypes = readField('auxMachineTypes');
          return auxMachineTypes;
        },
      },
      readExternalCompanies: {
        read(_, { readField }) {
          const externalCompanies = readField('externalCompanies');
          return externalCompanies;
        },
      },
    },
  },
};

function offsetFromCursor(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merged: any,
  cursor: string,
  readField: ReadFieldFunction,
  direction: boolean
) {
  for (let i = merged.length - 1; i >= 0; --i) {
    const item = merged[i];

    if (readField('id', item) === cursor) {
      return direction ? i + 1 : i;
    }
  }
  // Report that the cursor could not be found.
  return -1;
}

function mergeCache(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existing: any,
  cacheKey: string,
  cursor: string,
  readField: ReadFieldFunction,
  take: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: any
) {
  const absoluteTake = Math.abs(take);
  if (cursor === '' && take < 0) take = absoluteTake;
  const merged = existing[cacheKey] ? [...existing[cacheKey]] : [null];

  let isLastPageLoaded = existing?.pageInfo?.isLastPageLoaded;

  if (!isLastPageLoaded) {
    isLastPageLoaded = cursor === existing?.pageInfo?.lastCursor;
  }

  const mergeClean = merged.filter((el) => el);

  let offset = offsetFromCursor(mergeClean, cursor, readField, take > 0);

  if (offset < 0) offset = mergeClean.length;
  if (!cursor) offset = 0;

  const arrToAdd = variables[cacheKey].slice();
  const nullIndex = merged.indexOf(null);

  const startIndex = take >= 0 ? offset : offset + take;
  const endIndex = take >= 0 ? offset + take : offset;
  const oldCursor = cursor;

  if (merged.length > absoluteTake + 1 && cursor === '') {
    cursor =
      merged[merged.length > absoluteTake ? 12 : merged.length - 2].__ref.split(
        ':'
      )[1];
  }

  const indexOfCursorInTheArray = merged.findIndex((item: { __ref: string }) =>
    item ? readField('id', item) === cursor : false
  );

  if (
    indexOfCursorInTheArray !== -1 &&
    indexOfCursorInTheArray !== 0 &&
    merged[indexOfCursorInTheArray - 1] !== null &&
    take < 0
  ) {
    return {
      ...variables,
      [cacheKey]: merged,
    };
  }

  const indexOfFirstCoincidenceArrToAdd = findFirstCommonIndex(
    merged,
    arrToAdd
  );

  const indexOfFirstCoincidenceMerged = findFirstCommonIndex(arrToAdd, merged);

  if (
    cursor &&
    cursor === readField('id', merged[merged.length - 1]) &&
    nullIndex % absoluteTake !== 0
  ) {
    merged.splice(nullIndex + 1, 0, arrToAdd[0]);

    return {
      ...variables,
      pageInfo: {
        ...variables.pageInfo,
        isLastPageLoaded: true,
      },
      [cacheKey]: merged,
    };
  }

  const arrToCompare = mergeClean.slice(startIndex, endIndex);

  if (JSON.stringify(arrToAdd) === JSON.stringify(arrToCompare)) {
    return {
      ...variables,
      pageInfo: {
        ...variables.pageInfo,
        isLastPageLoaded,
      },
      [cacheKey]: merged,
    };
  }

  const existingArrayWithNull = merged.slice(
    take > 0 ? offset : offset + take,
    take > 0 ? offset + take : offset
  );

  const existNull =
    existingArrayWithNull.length > 1
      ? existingArrayWithNull.some(
          (item: { __ref: string } | null) => item === null
        )
      : false;

  // If we have more than 13 orders different in the refetch should enter in this condition
  if (
    indexOfFirstCoincidenceArrToAdd === -1 &&
    indexOfFirstCoincidenceMerged === -1 &&
    (!oldCursor || oldCursor === '')
  ) {
    return {
      ...variables,
      [cacheKey]: [...arrToAdd, null],
    };
  }

  if (
    (indexOfFirstCoincidenceArrToAdd as number) >= 0 &&
    (indexOfFirstCoincidenceMerged as number) >= 0 &&
    (existNull || (indexOfFirstCoincidenceArrToAdd as number) > 0)
  ) {
    mergeArrays(
      nullIndex,
      merged,
      arrToAdd,
      indexOfFirstCoincidenceMerged as number,
      indexOfFirstCoincidenceArrToAdd as number
    );

    return {
      ...variables,
      pageInfo: {
        ...variables.pageInfo,
        isLastPageLoaded,
      },
      [cacheKey]: merged,
    };
  }
  // This can be better, could have problems
  // TEst different scenarios

  if (
    arrToCompare.length < absoluteTake &&
    cursor !== variables.pageInfo.lastCursor
  ) {
    const lastPageItems = arrToCompare.length;
    arrToAdd.splice(0, lastPageItems);
  }

  const insertionIndex =
    take > 0 && cursor !== variables.pageInfo.lastCursor
      ? nullIndex
      : nullIndex + 1;

  for (let i = arrToAdd.length - 1; i >= 0; i--) {
    const item = arrToAdd[i];
    merged.splice(insertionIndex, 0, item);
  }

  return {
    ...variables,
    pageInfo: {
      ...variables.pageInfo,
      isLastPageLoaded,
    },
    [cacheKey]: merged,
  };
}

function readCache(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existing: any,
  cacheKey: string,
  readField: ReadFieldFunction,
  cursor: string,
  take: number
) {
  const absoluteTake = Math.abs(take);
  if (cursor === '' && take < 0) take = absoluteTake;
  const cacheItems = existing?.[cacheKey] || [];
  let indexOfNull = cacheItems.indexOf(null);
  const pageInfo = existing?.pageInfo;

  if (cacheItems.length > existing?.totalCount) {
    indexOfNull = undefined;
  }

  const cursorCondition = cursor === '' && cacheItems.length === 0;

  if (cursorCondition) {
    return undefined;
  }

  // TO DO: REVIEW THIS CONDITION
  if (cursor === '' && indexOfNull > absoluteTake + 1) {
    cursor =
      cacheItems[
        cacheItems.length > absoluteTake + 1
          ? absoluteTake
          : cacheItems.length - 2
      ].__ref.split(':')[1];
    take = -absoluteTake;
  }

  // TO DO: This could be a Map or hashTable
  const existCursorInTheArray = cacheItems.some((item: { __ref: string }) =>
    item ? readField('id', item) === cursor : false
  );

  const cacheItemMinusOne = cacheItems[cacheItems.length - 1];

  if (
    (cacheItemMinusOne
      ? cursor === readField('id', cacheItemMinusOne)
      : false) &&
    cacheItems[cacheItems.length - 2] === null &&
    cacheItems.length < existing?.totalCount
  ) {
    return undefined;
  }

  if (
    pageInfo?.isLastPageLoaded &&
    (cursor === pageInfo?.lastCursor ||
      ((cacheItemMinusOne
        ? cursor === readField('id', cacheItemMinusOne)
        : false) &&
        take > 0))
  ) {
    const lastPageCount = getTake()(store.getState());
    const resultantItems = existing?.totalCount % lastPageCount.take;
    return {
      ...existing,
      [cacheKey]: cacheItems
        .filter((el: { __ref: string }) => el)
        .slice(resultantItems ? -resultantItems : -absoluteTake),
      pageInfo: {
        ...existing?.pageInfo,
        hasNextPage: false,
      },
    };
  }

  if (!existing?.pageInfo.hasNextPage && !existing?.pageInfo.hasPreviousPage) {
    return {
      ...existing,
      [cacheKey]: existing?.[cacheKey].filter((el: { __ref: string }) => el),
    };
  }

  const cacheItemAfterNull = cacheItems[indexOfNull + 1];
  const cacheItemBeforeNull = cacheItems[indexOfNull - 1];

  if (
    cursor !== '' &&
    indexOfNull !== undefined &&
    (!existCursorInTheArray ||
      (take < 0 &&
        (cacheItemAfterNull
          ? readField('id', cacheItemAfterNull) === cursor
          : cacheItemAfterNull)) ||
      (take > 0 &&
        (cacheItemBeforeNull
          ? readField('id', cacheItemBeforeNull) === cursor
          : cacheItemBeforeNull)))
  ) {
    return undefined;
  }

  let hasNextPage = pageInfo?.hasNextPage;
  if (take < 0 || cursor !== pageInfo?.lastCursor) {
    hasNextPage = true;
  } else if (
    (take > 0 && cursor === pageInfo?.lastCursor) ||
    cursor === pageInfo?.lastCursor
  ) {
    hasNextPage = false;
  }

  let offset = offsetFromCursor(
    cacheItems.filter((el: { __ref: string }) => el),
    cursor,
    readField,
    take > 0
  );

  const isLastPageLoaded = cursor === existing?.pageInfo?.lastCursor;
  const resultant = (existing?.[cacheKey]?.length - 1) % absoluteTake;
  if (
    (existing?.[cacheKey]?.length - 1) % absoluteTake !== 0 &&
    !isLastPageLoaded &&
    indexOfNull - resultant ===
      existing[cacheKey].findIndex(
        (item: { __ref: string }) => readField('id', item) === cursor
      ) +
        1
  ) {
    return undefined;
  }

  const existingArrayWithNull = cacheItems.slice(
    take > 0 ? offset : offset + take,
    take > 0 ? offset + take : offset
  );

  const indexOfNullInExistingArray = existingArrayWithNull.findIndex(
    (item: { __ref: string } | null) => item === null
  );

  if (
    indexOfNullInExistingArray !== 0 &&
    indexOfNullInExistingArray !== existingArrayWithNull.length - 1 &&
    indexOfNullInExistingArray !== -1
  ) {
    return undefined;
  }

  // Check has previous page
  if (take < 0 && offset === 0) {
    const hasPreviousPage = false;
    const existingArray = cacheItems
      .filter((el: { __ref: string }) => el)
      .slice(0, absoluteTake);
    return {
      ...existing,
      [cacheKey]: existingArray,
      pageInfo: {
        ...existing.pageInfo,
        hasPreviousPage,
        hasNextPage,
      },
    };
  } else {
    if (offset < 0) offset = 0;

    const existingArray = cacheItems
      .filter((el: { __ref: string }) => el)
      .slice(
        take > 0 ? offset : offset + take,
        take > 0 ? offset + take : offset
      );

    const hasPreviousPage = offset > 0;

    return existingArray.length === 0 && offset > 0
      ? undefined
      : {
          ...existing,
          [cacheKey]: existingArray,
          pageInfo: {
            ...existing?.pageInfo,
            hasNextPage,
            hasPreviousPage,
          },
        };
  }
}
