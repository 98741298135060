import { gql } from '@apollo/client';

export const FETCH_PREDEFINED_DATA_LISTS = gql`
  query FetchPredefinedDataLists {
    capitols {
      id
      name
      subCapitolsIDs
    }
    subCapitols {
      id
      name
      capitolIDs
      elementOrdersIds
    }
    elementOrders {
      id
      name
      subCapitolIDs
    }
    classifications {
      id
      name
      coticId
    }
    predefinedOrders {
      id
      name
      capitolId
      subCapitolId
      elementOrderId
      activityId
    }

    externalCompanies {
      id
      companyName
    }
    animalAllStatus {
      id
      code
      description
      animalUnitIDs
    }
  }
`;

export const FETCH_CLASSIFICATIONS = gql`
  query FetchClassifications {
    classifications {
      id
      name
      coticId
    }
  }
`;

export const GET_CLASSIFICATION_BY_ID_OR_COTIC = gql`
  query GetClassificationById($id: ID, $coticId: Int) {
    classification(id: $id, coticId: $coticId) {
      id
      name
      coticId
    }
  }
`;

export const FETCH_CAPITOLS = gql`
  query FetchCapitols {
    capitols {
      id
      name
      subCapitolsIDs
    }
  }
`;

export const FETCH_SUB_CAPITOLS = gql`
  query FetchSubCapitols {
    subCapitols {
      id
      name
      capitolIDs
      elementOrdersIds
    }
  }
`;

export const FETCH_ELEMENT_ORDERS = gql`
  query FetchElementOrders {
    elementOrders {
      id
      name
      subCapitolIDs
    }
  }
`;

export const FETCH_PREDEFINED_ORDERS = gql`
  query FetchPredefinedOrders {
    predefinedOrders {
      id
      name
      capitolId
      subCapitolId
      elementOrderId
      activityId
    }
  }
`;

export const GET_CAPITOL_BY_ID = gql`
  query GetCapitolById($id: ID!) {
    capitol(id: $id) {
      id
      name
      subCapitolsIDs
    }
  }
`;

export const GET_SUB_CAPITOL_BY_ID = gql`
  query GetSubCapitolById($id: ID!) {
    subCapitol(id: $id) {
      id
      name
      capitolIDs
      elementOrdersIds
    }
  }
`;

export const GET_ELEMENT_ORDER_BY_ID = gql`
  query GetElementOrderById($id: ID!) {
    elementOrder(id: $id) {
      id
      name
      subCapitolIDs
    }
  }
`;

export const GET_SUB_CAPITOLS_BY_IDS = gql`
  query GetSubCapitolsByIds($ids: [ID!]!) {
    readSubCapitols(ids: $ids) @client {
      id
      name
      elementOrdersIds
    }
  }
`;

export const GET_ELEMENT_ORDERS_BY_ID = gql`
  query GetElementOrdersByIds($ids: [ID!]!) {
    readElementOrders(ids: $ids) @client {
      id
      name
      subCapitolIDs
    }
  }
`;
