import React from 'react';

import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';

export type AddBasicInfoAccident = {
  registVehicle: string;
  insurerVehicle: string;
  colorVehicle: string;
  modelVehicle: string;
  occupantsVehicle: string | number;
  policyVehicle: string | number;
  registTrailer: string;
  insurerTrailer: string;
  policyTrailer: string | number;
  letters: string;
};

export enum AccidentInput {
  RegistVehicle = 'registVehicle',
  InsurerVehicle = 'insurerVehicle',
  ColorVehicle = 'colorVehicle',
  ModelVehicle = 'modelVehicle',
  OccupantsVehicle = 'occupantsVehicle',
  PolicyVehicle = 'policyVehicle',
  RegistTrailer = 'registTrailer',
  InsurerTrailer = 'insurerTrailer',
  PolicyTrailer = 'policyTrailer',
  IsTrailerActive = 'isTrailerActive',
  Damages = 'damages',
  DamageSwitch = 'damageSwitch',
}

export type tableProps = React.TableHTMLAttributes<HTMLTableElement> & {
  namingPersistForm?: string;
  letter: string;
  removeTable: () => boolean;
  control: Control<AddBasicInfoAccident>;
  letters: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
};

export type dataTable = {
  column1: string;
  column2: string;
  column3: string;
};

export type displayProps = {
  show: boolean;
};

export type inputProps = {
  borderError?: boolean;
};
