// Sentry
import * as Sentry from '@sentry/react';

// Utils
import { getFilesFromDirectory } from './files';

// Types
import { Sketch } from '../components/forms/AddBasicInfoAccidentForm/types';

type imageData = {
  creatingDate: string;
  cloudinaryPublicId: string;
  name: string;
};

export const UploadImage = async (
  picturesDirectoryName: string,
  canvasDirectoryName?: string
) => {
  const arrayImages: imageData[] = [];

  const files = (await getFilesFromDirectory(picturesDirectoryName)).map(
    (item) => item.file
  );

  const imagesBlob = files.map((file) => new Blob([file], { type: file.type }));

  if (canvasDirectoryName) {
    const [fileCanvas] = await getFilesFromDirectory(canvasDirectoryName);
    if (fileCanvas) {
      const blobCanvas = new Blob([fileCanvas.file], {
        type: fileCanvas.file.type,
      });
      const nameCanvas = fileCanvas.file.name + '_canvas';

      const dataCanvas = new FormData();
      dataCanvas.append('file', blobCanvas);
      dataCanvas.append('upload_preset', `ml_ora_${import.meta.env.MODE}`);

      try {
        const response = await fetch(
          'https://api.cloudinary.com/v1_1/' +
            import.meta.env.VITE_CLOUD_NAME +
            '/image/upload',
          {
            method: 'POST',
            body: dataCanvas,
          }
        );

        const imageData = await response.json();

        arrayImages.push({
          creatingDate: imageData.created_at,
          cloudinaryPublicId: imageData.public_id,
          name: nameCanvas,
        });
      } catch (err) {
        Sentry.captureException(err);
        console.log('> Error uploading croquis', err);
      }
    }
  }

  try {
    await Promise.all(
      imagesBlob.map(async (file, index) => {
        const blob = file;
        const data = new FormData();
        data.append('file', blob);
        data.append('upload_preset', `ml_ora_${import.meta.env.MODE}`);

        const response = await fetch(
          'https://api.cloudinary.com/v1_1/' +
            import.meta.env.VITE_CLOUD_NAME +
            '/image/upload',
          {
            method: 'POST',
            body: data,
          }
        );

        const imageData = await response.json();
        arrayImages.push({
          creatingDate: imageData.created_at,
          cloudinaryPublicId: imageData.public_id,
          name: files[index].name,
        });
      })
    );
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error uploading images', err);
  }

  return arrayImages;
};

export function compareSketchJson(
  modifiedSketchJson: string | null,
  originalSketchJson: string | null
) {
  let sketchToSaveOrUpdate: Sketch | null = null;
  let sketchToRemove: Sketch | null = null;

  if (modifiedSketchJson && !originalSketchJson) {
    // New sketch to save
    sketchToSaveOrUpdate = JSON.parse(modifiedSketchJson);
  } else if (!modifiedSketchJson && originalSketchJson) {
    // Sketch to remove
    sketchToRemove = JSON.parse(originalSketchJson);
  } else if (modifiedSketchJson && originalSketchJson) {
    // Sketch to update
    const modifiedSketchJsonParsed = JSON.parse(modifiedSketchJson);
    const originalSketchJsonParsed = JSON.parse(originalSketchJson);

    if (modifiedSketchJsonParsed.name !== originalSketchJsonParsed.name) {
      sketchToSaveOrUpdate = modifiedSketchJsonParsed;
      sketchToRemove = originalSketchJsonParsed;
    }
  }

  return { sketchToSaveOrUpdate, sketchToRemove };
}

// Function to compare two JSONs and generate arrays of images to save and remove
export function compareImageJSONs(
  originalJson?: string,
  modifiedJson?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { imagesToSave: any[]; imagesToRemove: any[] } {
  // Arrays to store images to add and remove
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imagesToSave: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imagesToRemove: any[] = [];

  if (originalJson && modifiedJson) {
    // Parse JSON strings into objects
    const originalImages = JSON.parse(originalJson);
    const modifiedImages = JSON.parse(modifiedJson);

    // Convert arrays of objects to sets of image names for easy comparison
    const originalNamesSet = new Set(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      originalImages.map((image: any) => image.name)
    );
    const modifiedNamesSet = new Set(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      modifiedImages.map((image: any) => image.name)
    );

    // Determine images to save (present in modified JSON but not in the original)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modifiedImages.forEach((image: any) => {
      if (!originalNamesSet.has(image.name)) {
        imagesToSave.push(image);
      }
    });

    // Determine images to remove (present in original JSON but not in the modified)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalImages.forEach((image: any) => {
      if (!modifiedNamesSet.has(image.name)) {
        imagesToRemove.push(image);
      }
    });
  }

  return {
    imagesToSave,
    imagesToRemove,
  };
}
