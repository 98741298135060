// Dependencies
import { redirect } from 'react-router-dom';
import {
  ApolloQueryResult,
  FetchResult,
  Reference,
  StoreObject,
} from '@apollo/client';

//Types
import { VehicleResponse } from '../types';
import { OrderType } from '../../../../../../types/orders';
import {
  tableIds,
  takeBase,
} from '../../../../../../components/display/tables/types';

// Constants
import {
  directoryNaming,
  formAccidentNaming,
} from '../../../../../../components/forms/AddBasicInfoAccidentForm/constants';
import { formVehicleNaming } from '../../../../../../components/forms/AddVehicleForm/constants';

// GQL
import { client } from '../../../../../../gql/client';
import {
  CREATE_NEW_IMAGE,
  DELETE_MANY_IMAGES,
  UPDATE_ACCIDENT_ORDER,
  UPDATE_ACCIDENT_ORDER_RELATIONSHIPS,
  UPDATE_NEW_ATTENTION_ACCIDENT,
} from '../../../../../../gql/mutations/accidents';

import {
  UPDATE_ORDER,
  UPDATE_ORDER_RELATIONSHIPS,
} from '../../../../../../gql/mutations/orders';
import { GET_ORDER_BY_ID } from '../../../../../../gql/queries/orders/orders';
import {
  GET_VEHICLE_BY_ID,
  GET_VEHICLE_OCCUPANT_BY_ID,
} from '../../../../../../gql/queries/vehicles';
import {
  CREATE_NEW_VEHICLE,
  DELETE_MANY_VEHICLES_BY_PLATES,
  NEW_VEHICLE_OCCUPANT_ACCIDENT,
  UPDATE_VEHICLE_BY_PLATE,
} from '../../../../../../gql/mutations/vehicles';
import { NEW_IMAGE } from '../../../../../../gql/fragments/image';
import { GET_ACCIDENT_BY_ID } from '../../../../../../gql/queries/accidents/accidents';
import { GET_ATTENTION_ACCIDENT_BY_ID } from '../../../../../../gql/queries/accidents/attentionAccidents';

// Utils
import { decompressSessionStorage } from '../../../../../../utils/sessionStorage';
import { removeNewAccidentStorage } from '../../../../../../utils/resetData';
import {
  difference,
  splitDifference,
  removeArraysAtFirstLevel,
} from '../../../../../../utils/difference';
import {
  compareImageJSONs,
  compareSketchJson,
  UploadImage,
} from '../../../../../../utils/image';
import { truncateArrayAfterNull } from '../../../../../../utils/mergeArrays';

// Sentry
import * as Sentry from '@sentry/react';

// Redux
import { store } from '../../../../../../state/configureStore';
import { setCursor } from '../../../../../../state/actions/ui/cursorTables';
import { getCursor } from '../../../../../../state/selectors/ui/tables';

export const saveData = async () => {
  const newAccidentForm = sessionStorage.getItem(
    formAccidentNaming.EDIT_ACCIDENT_FORM
  );

  const accidentDataForm = JSON.parse(
    decompressSessionStorage(newAccidentForm as string)
  );

  const accidentId = accidentDataForm.accidentId;
  const attentionAccidentId = accidentDataForm.attentionAccidentId;
  const orderId = accidentDataForm.orderId;

  // Redux data
  const dispatch = store.dispatch;
  const cursorMultimedia = getCursor(tableIds.MULTIMEDIA)(store.getState());

  try {
    const basicInfoAccidentForm = sessionStorage.getItem(
      formAccidentNaming.EDIT_BASIC_INFO_ACCIDENT_FORM
    );

    const basicInfoAccidentDataForm = JSON.parse(
      decompressSessionStorage(basicInfoAccidentForm as string)
    );

    const basicInfoToCompare = basicInfoAccidentDataForm.dataToCompare;
    delete basicInfoAccidentDataForm.dataToCompare;

    const basicInfoAccidentDataFormClean = removeArraysAtFirstLevel(
      basicInfoAccidentDataForm
    );

    const vehiclesForm = sessionStorage.getItem(
      formVehicleNaming.EDIT_VEHICLE_FORM
    );

    const vehiclesDataForm = JSON.parse(
      decompressSessionStorage(vehiclesForm as string)
    );

    const vehiclesDataToCompare = vehiclesDataForm.dataToCompare;

    vehiclesDataToCompare?.letters &&
      vehiclesDataToCompare?.letters.length > 0 &&
      (vehiclesDataToCompare.letters = JSON.parse(
        vehiclesDataToCompare.letters
      ));

    delete vehiclesDataForm.dataToCompare;
    delete vehiclesDataForm.orderParentCotic;
    delete vehiclesDataForm.orderParentId;
    delete vehiclesDataForm.intentVehicles;

    vehiclesDataForm?.letters &&
      vehiclesDataForm?.letters.length > 0 &&
      (vehiclesDataForm.letters = JSON.parse(vehiclesDataForm.letters));

    const accidentDataToCompare = accidentDataForm.dataToCompare;
    delete accidentDataForm.dataToCompare;
    delete accidentDataForm.intent;
    const accidentDataFormClean = removeArraysAtFirstLevel(accidentDataForm);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const basicInfoDifference: any = difference(
      basicInfoAccidentDataFormClean,
      basicInfoToCompare
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const vehiclesDifference: any = splitDifference(
      vehiclesDataForm,
      vehiclesDataToCompare
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const accidentDataDifference: any = difference(
      accidentDataFormClean,
      accidentDataToCompare
    );

    const { sketchToSaveOrUpdate, sketchToRemove } = compareSketchJson(
      basicInfoAccidentDataFormClean.sketch,
      basicInfoToCompare.sketch
    );

    const { imagesToSave, imagesToRemove } = compareImageJSONs(
      basicInfoToCompare.images,
      basicInfoAccidentDataFormClean.images
    );

    const imageArray =
      (imagesToSave || []).length || sketchToSaveOrUpdate
        ? await UploadImage(
            OrderType.Accident,
            sketchToSaveOrUpdate ? directoryNaming.CANVAS : undefined
          )
        : [];

    const imageArrayWithDeletes = imageArray.map(async (image) =>
      client.mutate({
        mutation: CREATE_NEW_IMAGE,
        variables: {
          input: {
            name: image.name,
            creatingDate: image.creatingDate,
            cloudinaryPublicId: image.cloudinaryPublicId,
            orderId: orderId,
          },
        },
        update(cache, { data: { createImage } }) {
          cache.modify({
            fields: {
              images(existingImages = []) {
                const newImageRef = cache.writeFragment({
                  data: createImage,
                  fragment: NEW_IMAGE,
                });
                return [...existingImages, newImageRef];
              },
            },
          });
        },
      })
    );

    const deletedImages = imagesToRemove?.length;
    const addedImages = imagesToSave?.length;

    client.cache.modify({
      fields: {
        orders(existingOrders = {}, { readField }) {
          const newOrderRef = {
            __ref: `Order:${orderId}`,
          };

          const images: readonly string[] | undefined = readField(
            'imageIDs',
            newOrderRef
          );

          if (images?.length === 0 && addedImages > 0) {
            const order = client.readQuery({
              query: GET_ORDER_BY_ID,
              variables: {
                id: orderId,
              },
            });

            let mostCloseOrder: undefined | string;

            const dateOrder = new Date(order.order.creationDate);

            for (let i = 0; i < existingOrders?.orders?.length - 1; i++) {
              const idOrder = existingOrders?.orders[i]?.__ref.split(':')[1];

              const currentOrder = client.readQuery({
                query: GET_ORDER_BY_ID,
                variables: {
                  id: idOrder,
                },
              });
              const currentOrderDate = new Date(
                currentOrder?.order?.creationDate
              );

              if (dateOrder.getTime() > currentOrderDate.getTime()) {
                mostCloseOrder = existingOrders?.orders[i]?.__ref.split(':')[1];
                break;
              }
            }

            if (!mostCloseOrder) {
              return {
                ...existingOrders,
              };
            }

            const mostCloseOrderIndex = existingOrders.orders.findIndex(
              (item: Reference | StoreObject) =>
                readField('id', item) === mostCloseOrder
            );
            const truncatedArray = truncateArrayAfterNull(
              existingOrders.orders
            );

            truncatedArray.splice(mostCloseOrderIndex, 0, newOrderRef);

            const newOrdersArray = truncatedArray;

            newOrdersArray.splice(-2, 1);

            const setCursorMultimedia = {
              ...cursorMultimedia,
              take: takeBase,
              cursor: '',
              orderBy: 'desc',
            };

            if (existingOrders?.orders?.length > 0) {
              dispatch(setCursor(tableIds.MULTIMEDIA, setCursorMultimedia));
            }
            return {
              ...existingOrders,
              orders: newOrdersArray,
              totalCount: existingOrders.totalCount + 1,
              pageInfo: {},
            };
          } else {
            return {
              ...existingOrders,
            };
          }
        },
      },
    });

    const imagesToRemoveIds = [
      ...(imagesToRemove?.map((image) => image.id) ?? []),
      ...(sketchToRemove?.id ? [sketchToRemove.id] : []),
    ];

    imagesToRemoveIds?.length
      ? imageArrayWithDeletes.push(
          client.mutate({
            mutation: DELETE_MANY_IMAGES,
            variables: {
              ids: imagesToRemoveIds,
            },
            update(cache, { data: { deleteManyImagesByIds } }) {
              deleteManyImagesByIds.forEach(
                (image: { id: Reference | StoreObject }) => {
                  cache.modify({
                    id: cache.identify(image.id),
                    fields: {
                      images(_, { DELETE }) {
                        return DELETE;
                      },
                    },
                  });
                }
              );
            },
          })
        )
      : undefined;

    client.cache.modify({
      fields: {
        orders(existingOrders = {}, { readField }) {
          const newOrderRef = {
            __ref: `Order:${orderId}`,
          };
          const images: readonly string[] | undefined = readField(
            'imageIDs',
            newOrderRef
          );
          if (
            (images?.length ?? 0) + addedImages - deletedImages === 0 &&
            existingOrders.orders.some((order: StoreObject | Reference) => {
              const ref = readField('id', order);
              return ref === orderId;
            })
          ) {
            const truncatedArray = truncateArrayAfterNull(
              existingOrders.orders
            );
            const filterOrders = truncatedArray.filter((order) => {
              const ref = readField('id', order as StoreObject | Reference);
              return ref !== orderId;
            });

            filterOrders.splice(-13, 12);

            const setCursorMultimedia = {
              ...cursorMultimedia,
              take: takeBase,
              cursor: '',
              orderBy: 'desc',
            };

            if (existingOrders?.orders?.length > 0) {
              dispatch(setCursor(tableIds.MULTIMEDIA, setCursorMultimedia));
            }

            return {
              ...existingOrders,
              orders: filterOrders,
              totalCount: existingOrders.totalCount - 1,
              pageInfo: {},
            };
          } else {
            return {
              ...existingOrders,
            };
          }
        },
      },
    });

    await Promise.all(imageArrayWithDeletes);

    await Promise.all([
      accidentDataDifference?.capitol || accidentDataDifference?.subCapitol
        ? client.mutate({
            mutation: UPDATE_ORDER_RELATIONSHIPS,
            variables: {
              input: {
                id: orderId,
                capitol: accidentDataDifference?.capitol
                  ? accidentDataDifference.capitol
                  : undefined,
                subCapitol: accidentDataDifference.subCapitol
                  ? accidentDataDifference.subCapitol
                  : undefined,
              },
            },
          })
        : undefined,
      accidentDataDifference?.description ||
      accidentDataDifference?.registerInitDate
        ? client.mutate({
            mutation: UPDATE_ORDER,
            variables: {
              input: {
                id: orderId,
                description: accidentDataDifference?.description
                  ? accidentDataDifference.description
                  : undefined,
                registerInitDate: accidentDataDifference?.registerInitDate
                  ? accidentDataDifference.registerInitDate
                  : undefined,
              },
            },
          })
        : undefined,
      basicInfoDifference.noticeConservationCenterDate ||
      basicInfoDifference.arrivalConservationCenterDate ||
      basicInfoDifference.departureConservationCenterDate ||
      basicInfoDifference.noticeSquadBoysDate ||
      basicInfoDifference.arrivalSquadBoysDate ||
      basicInfoDifference.departureSquadBoysDate ||
      basicInfoDifference.noticeRoadTeamDate ||
      basicInfoDifference.arrivalRoadTeamDate ||
      basicInfoDifference.departureRoadTeamDate ||
      basicInfoDifference.noticeControlCenterDate ||
      basicInfoDifference.arrivalControlCenterDate ||
      basicInfoDifference.departureControlCenterDate ||
      basicInfoDifference.noticeAmbulanceCraneAssistanceDate ||
      basicInfoDifference.arrivalAmbulanceCraneAssistanceDate ||
      basicInfoDifference.departureAmbulanceCraneAssistanceDate ||
      basicInfoDifference.otherName ||
      basicInfoDifference.noticeOtherDate ||
      basicInfoDifference.arrivalOtherDate ||
      basicInfoDifference.departureOtherDate
        ? client.mutate({
            mutation: UPDATE_NEW_ATTENTION_ACCIDENT,
            variables: {
              input: {
                id: attentionAccidentId,
                noticeConservationCenterDate:
                  basicInfoDifference?.noticeConservationCenterDate
                    ? basicInfoDifference.noticeConservationCenterDate
                    : undefined,
                arrivalConservationCenterDate:
                  basicInfoDifference?.arrivalConservationCenterDate
                    ? basicInfoDifference.arrivalConservationCenterDate
                    : undefined,
                departureConservationCenterDate:
                  basicInfoDifference?.departureConservationCenterDate
                    ? basicInfoDifference.departureConservationCenterDate
                    : undefined,
                noticeSquadBoysDate: basicInfoDifference?.noticeSquadBoysDate
                  ? basicInfoDifference.noticeSquadBoysDate
                  : undefined,
                arrivalSquadBoysDate: basicInfoDifference?.arrivalSquadBoysDate
                  ? basicInfoDifference.arrivalSquadBoysDate
                  : undefined,
                departureSquadBoysDate:
                  basicInfoDifference?.departureSquadBoysDate
                    ? basicInfoDifference.departureSquadBoysDate
                    : undefined,
                noticeRoadTeamDate: basicInfoDifference?.noticeRoadTeamDate
                  ? basicInfoDifference.noticeRoadTeamDate
                  : undefined,
                arrivalRoadTeamDate: basicInfoDifference?.arrivalRoadTeamDate
                  ? basicInfoDifference.arrivalRoadTeamDate
                  : undefined,
                departureRoadTeamDate:
                  basicInfoDifference?.departureRoadTeamDate
                    ? basicInfoDifference.departureRoadTeamDate
                    : undefined,
                noticeControlCenterDate:
                  basicInfoDifference?.noticeControlCenterDate
                    ? basicInfoDifference.noticeControlCenterDate
                    : undefined,
                arrivalControlCenterDate:
                  basicInfoDifference?.arrivalControlCenterDate
                    ? basicInfoDifference.arrivalControlCenterDate
                    : undefined,
                departureControlCenterDate:
                  basicInfoDifference?.departureControlCenterDate
                    ? basicInfoDifference.departureControlCenterDate
                    : undefined,
                noticeAmbulanceCraneAssistanceDate:
                  basicInfoDifference?.noticeAmbulanceCraneAssistanceDate
                    ? basicInfoDifference.noticeAmbulanceCraneAssistanceDate
                    : undefined,
                arrivalAmbulanceCraneAssistanceDate:
                  basicInfoDifference?.arrivalAmbulanceCraneAssistanceDate
                    ? basicInfoDifference.arrivalAmbulanceCraneAssistanceDate
                    : undefined,
                departureAmbulanceCraneAssistanceDate:
                  basicInfoDifference?.departureAmbulanceCraneAssistanceDate
                    ? basicInfoDifference.departureAmbulanceCraneAssistanceDate
                    : undefined,
                otherName: basicInfoDifference?.otherName
                  ? basicInfoDifference.otherName
                  : undefined,
                noticeOtherDate: basicInfoDifference?.noticeOtherDate
                  ? basicInfoDifference.noticeOtherDate
                  : undefined,
                arrivalOtherDate: basicInfoDifference?.arrivalOtherDate
                  ? basicInfoDifference.arrivalOtherDate
                  : undefined,
                departureOtherDate: basicInfoDifference?.departureOtherDate
                  ? basicInfoDifference.departureOtherDate
                  : undefined,
              },
            },
          })
        : undefined,
      accidentDataDifference.registerInitDate ||
      accidentDataDifference.direction ||
      accidentDataDifference.margin ||
      accidentDataDifference.lane ||
      accidentDataDifference.track ||
      accidentDataDifference.pkInit ||
      accidentDataDifference.pkEnd ||
      basicInfoDifference.laneCutting !== undefined ||
      basicInfoDifference.policeReport !== undefined ||
      vehiclesDifference.differences?.damages !== undefined ||
      basicInfoDifference.policeReportNumber ||
      basicInfoDifference.roadConditionDescription
        ? client.mutate({
            mutation: UPDATE_ACCIDENT_ORDER,
            variables: {
              input: {
                id: accidentId,
                registerInitDate: accidentDataDifference?.registerInitDate
                  ? accidentDataDifference.registerInitDate
                  : undefined,
                direction: accidentDataDifference.direction
                  ? accidentDataDifference.direction
                  : undefined,
                margin: accidentDataDifference.margin
                  ? accidentDataDifference.margin
                  : undefined,
                lane: accidentDataDifference.lane
                  ? accidentDataDifference.lane
                  : undefined,
                track: accidentDataDifference.track
                  ? accidentDataDifference.track
                  : undefined,
                pkInitKm: accidentDataDifference.pkInit
                  ? parseInt(accidentDataDifference.pkInit.split('+')[0])
                  : undefined,
                pkInitMeter: accidentDataDifference.pkInit
                  ? parseInt(accidentDataDifference.pkInit.split('+')[1])
                  : undefined,
                pkEndKm: accidentDataDifference.pkEnd
                  ? parseInt(accidentDataDifference.pkEnd.split('+')[0])
                  : undefined,
                pkEndMeter: accidentDataDifference.pkEnd
                  ? parseInt(accidentDataDifference.pkEnd.split('+')[1])
                  : undefined,
                laneCutting:
                  basicInfoDifference.laneCutting !== undefined
                    ? basicInfoDifference.laneCutting
                    : undefined,
                policeReport:
                  basicInfoDifference.policeReport !== undefined
                    ? basicInfoDifference.policeReport
                    : undefined,
                policeReportNumber: basicInfoDifference.policeReportNumber
                  ? basicInfoDifference.policeReportNumber
                  : undefined,
                roadConditionDescription:
                  basicInfoDifference.roadConditionDescription
                    ? basicInfoDifference.roadConditionDescription
                    : undefined,
                damages: vehiclesDifference.differences?.damages
                  ? vehiclesDifference.differences.damages
                  : undefined,
              },
            },
          })
        : undefined,
    ]);

    const switchValue = JSON.parse(vehiclesDataForm.involvedVehiclesSwitch);

    const vehicleIDs: string[] = [];
    const vehicleOccupantIDs: string[] = [];
    let vehicleAndVehicleOccupantsIDs;

    if (switchValue) {
      vehicleAndVehicleOccupantsIDs = (await Promise.all([
        // Process added vehicles
        ...Object.entries(vehiclesDifference.added?.vehicles || {}).map(
          async ([, vehicle]: any) => {
            let dataTrailer: FetchResult | undefined = undefined;

            const dataVehicle = await client.mutate({
              mutation: CREATE_NEW_VEHICLE,
              variables: {
                input: {
                  plate: vehicle.registVehicle,
                  model: vehicle.modelVehicle || null,
                  color: vehicle.colorVehicle || null,
                  insurance: vehicle.insurerVehicle || null,
                  policyNumber: vehicle.policyVehicle || null,
                  isInternal: false,
                  isTrailer: false,
                  isActive: false,
                },
              },
            });

            vehicleIDs.push(dataVehicle.data.createVehicle.id);

            const dataVehicleOccupant = await client.mutate({
              mutation: NEW_VEHICLE_OCCUPANT_ACCIDENT,
              variables: {
                input: {
                  numberOccupants: parseInt(vehicle.occupantsVehicle) || 0,
                  vehicleId: dataVehicle.data.createVehicle.id,
                },
              },
            });

            vehicleOccupantIDs.push(
              dataVehicleOccupant.data.createVehicleOccupantAccident.id
            );

            if (
              vehicle.insurerTrailer ||
              vehicle.registTrailer ||
              vehicle.policyTrailer
            ) {
              dataTrailer = await client.mutate({
                mutation: CREATE_NEW_VEHICLE,
                variables: {
                  input: {
                    plate: vehicle.registTrailer,
                    insurance: vehicle.insurerTrailer,
                    policyNumber: vehicle.policyTrailer,
                    isInternal: false,
                    isTrailer: true,
                    parentTrailerRelationshipId:
                      dataVehicle.data.createVehicle.id,
                    isActive: false,
                  },
                },
              });

              vehicleIDs.push(dataTrailer.data?.createVehicle.id);
            }

            return {
              vehicle: dataVehicle.data.createVehicle.id,
              vehicleTrailer: dataTrailer?.data?.createVehicle?.id ?? undefined,
              vehicleOccupant:
                dataVehicleOccupant.data.createVehicleOccupantAccident.id,
            };
          }
        ),

        // Process modified vehicles
        ...Object.entries(vehiclesDifference.modified?.vehicles || {}).map(
          async ([, vehicle]: any) => {
            const updateVehicle = await client.mutate({
              mutation: UPDATE_VEHICLE_BY_PLATE,
              variables: {
                input: {
                  plate: vehicle.registVehicle,
                  model: vehicle.modelVehicle || null,
                  color: vehicle.colorVehicle || null,
                  insurance: vehicle.insurerVehicle || null,
                  policyNumber: vehicle.policyVehicle || null,
                  isInternal: false,
                  isTrailer: vehicle.isTrailerActive === 'true',
                  isActive: false,
                },
              },
            });
            if (vehicle.isTrailerActive !== 'true') {
              return {
                vehicle: updateVehicle.data.updateVehicle.id,
              };
            } else {
              return {
                vehicleTrailer: updateVehicle.data.updateVehicle.id,
              };
            }
          }
        ),

        // Process deleted vehicles in a single mutation
        ...(vehiclesDifference.removed?.registVehicle?.length
          ? [
              client.mutate({
                mutation: DELETE_MANY_VEHICLES_BY_PLATES,
                variables: {
                  plates: vehiclesDifference.removed.registVehicle, // We eliminate all vehicles in a single call
                },
                update(cache, { data: { deleteManyVehiclesByPlates } }) {
                  deleteManyVehiclesByPlates.forEach(
                    (vehicle: { id: Reference | StoreObject }) => {
                      cache.modify({
                        id: cache.identify(vehicle.id),
                        fields: {
                          vehicles(_, { DELETE }) {
                            return DELETE;
                          },
                        },
                      });
                    }
                  );
                },
              }),
            ]
          : []),
      ] as const)) as VehicleResponse[];
    }

    if (
      accidentDataDifference?.concession ||
      accidentDataDifference?.road ||
      basicInfoDifference?.surveillanceBody ||
      basicInfoDifference?.weather ||
      basicInfoDifference?.surfaceCondition ||
      basicInfoDifference?.mediumKnowledge ||
      basicInfoDifference?.luminosity ||
      basicInfoDifference?.conditionsDriving ||
      vehicleIDs.length > 0 ||
      vehicleOccupantIDs.length > 0
    ) {
      const {
        data: { accident },
      } = await client.query({
        fetchPolicy: 'network-only',
        query: GET_ACCIDENT_BY_ID,
        variables: { id: accidentId },
      });

      const vehicleOccupantRelationships =
        vehicleOccupantIDs.length > 0
          ? accident?.vehicleOccupantIDs
            ? [...accident.vehicleOccupantIDs, ...vehicleOccupantIDs]
            : [...vehicleOccupantIDs]
          : undefined;

      const vehicleRelationships =
        vehicleIDs.length > 0
          ? accident?.vehicleIDs
            ? [...accident.vehicleIDs, ...vehicleIDs]
            : [...vehicleIDs]
          : undefined;

      await client.mutate({
        mutation: UPDATE_ACCIDENT_ORDER_RELATIONSHIPS,
        variables: {
          input: {
            id: accidentId,
            concessionId: accidentDataDifference?.concession
              ? accidentDataDifference.concession
              : undefined,
            roadId: accidentDataDifference?.road
              ? accidentDataDifference.road
              : undefined,
            surveillanceBodyId: basicInfoDifference?.surveillanceBody
              ? basicInfoDifference.surveillanceBody
              : undefined,
            mediumKnowledgeId: basicInfoDifference?.mediumKnowledge
              ? basicInfoDifference.mediumKnowledge
              : undefined,
            weatherId: basicInfoDifference?.weather
              ? basicInfoDifference.weather
              : undefined,
            surfaceConditionId: basicInfoDifference?.surfaceCondition
              ? basicInfoDifference.surfaceCondition
              : undefined,
            luminosityId: basicInfoDifference?.luminosity
              ? basicInfoDifference.luminosity
              : undefined,
            conditionsDrivingId: basicInfoDifference?.conditionsDriving
              ? basicInfoDifference.conditionsDriving
              : undefined,
            vehicleOccupantRelationships,
            vehicleRelationships,
          },
        },
      });
    }

    // Resolve promises related to vehicles, occupants, and trailers.
    const unresolvedPromiseVehicles: Promise<ApolloQueryResult<object>>[] = [];

    (vehicleAndVehicleOccupantsIDs || []).forEach(
      (vehicleAndVehicleOccupantId) => {
        if (vehicleAndVehicleOccupantId.vehicle) {
          unresolvedPromiseVehicles.push(
            client.query({
              fetchPolicy: 'network-only',
              query: GET_VEHICLE_BY_ID,
              variables: { id: vehicleAndVehicleOccupantId.vehicle },
            })
          );
        }

        if (vehicleAndVehicleOccupantId.vehicleTrailer) {
          unresolvedPromiseVehicles.push(
            client.query({
              fetchPolicy: 'network-only',
              query: GET_VEHICLE_BY_ID,
              variables: { id: vehicleAndVehicleOccupantId.vehicleTrailer },
            })
          );
        }
        if (vehicleAndVehicleOccupantId.vehicleOccupant) {
          unresolvedPromiseVehicles.push(
            client.query({
              fetchPolicy: 'network-only',
              query: GET_VEHICLE_OCCUPANT_BY_ID,
              variables: { id: vehicleAndVehicleOccupantId.vehicleOccupant },
            })
          );
        }
      }
    );

    // Resolve all pledges in parallel
    await Promise.all([
      client.query({
        fetchPolicy: 'network-only',
        query: GET_ORDER_BY_ID,
        variables: { id: orderId },
      }),
      client.query({
        fetchPolicy: 'network-only',
        query: GET_ACCIDENT_BY_ID,
        variables: { id: accidentId },
      }),
      ...unresolvedPromiseVehicles,
      client.query({
        fetchPolicy: 'network-only',
        query: GET_ATTENTION_ACCIDENT_BY_ID,
        variables: {
          id: attentionAccidentId,
        },
      }),
    ]);

    removeNewAccidentStorage();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return redirect(`../../../../accidents/${orderId}`);
  } catch (error) {
    if (orderId) {
      await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: orderId,
        },
        fetchPolicy: 'network-only',
      });
    }
    Sentry.captureException(error);
    console.log('Error save Accident:', error);
  }
  return null;
};
