import { Role } from '../../../../types/auth';

export const data = [
  {
    title: 'Nova comunicació',
    path: '',
    permissions: [Role.Ceconsa, Role.SuperAdmin, Role.RoomTeam],
    subItems: [
      {
        text: 'Duplicar comunicació',
        path: 'duplicate-communication',
        permissions: [Role.Ceconsa, Role.SuperAdmin, Role.RoomTeam],
      },
    ],
  },
  {
    title: 'Buscar i crear històrics',
    path: 'search-create-historical-communication',
    permissions: [Role.Ceconsa, Role.SuperAdmin, Role.RoomTeam],
  },
];
