import { defer, LoaderFunction } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../../gql/client';
import { FETCH_PREDEFINED_OPTIONS_ACCIDENTS } from '../../../../../gql/queries/accidents/predefinedData';
import { selectBasicAccidentInformation } from '../../../../../gql/selectors/accidents';

// Utils
import { formAccidentNaming } from '../../../../../components/forms/AddBasicInfoAccidentForm/constants';
import { AuthContextType } from '../../../../../hooks/useAuth';
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';
//TODO: la estructura parece que esta bien sin no mirar pantallazos pero no se esta rellenando los datos para que se representem bien por eso creo que se buguea

// Types
import { type Accident } from '../../../../../types/accident';

const loadBasicInformationSummary = async (idOrder: string) => {
  try {
    const workOrderData = await selectBasicAccidentInformation(idOrder);

    return workOrderData;
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Work Order Summary loading error:', err);
    return true;
  }
};

const loadFormOptions = async (userData: AuthContextType | null) => {
  try {
    if (userData?.user) {
      const { data } = await client.query({
        query: FETCH_PREDEFINED_OPTIONS_ACCIDENTS,
      });

      return {
        ...data,
      };
    }
    return {};
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Accident Summary loading error:', err);
    return true;
  }
};

export const EditBasicInfoAccidentLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formAccidentNaming.EDIT_BASIC_INFO_ACCIDENT_FORM
  );

  const stringifyAccidentForm = sessionStorage.getItem(
    formAccidentNaming.EDIT_ACCIDENT_FORM
  );

  let road,
    accidentData: Accident,
    orderParentCotic,
    orderParentId,
    registerInitDate;
  const loaderToResolve = await loadFormOptions(userData);
  if (stringifyAccidentForm) {
    accidentData = JSON.parse(decompressSessionStorage(stringifyAccidentForm));

    orderParentCotic = accidentData.cotic;
    orderParentId = accidentData.orderId;
    registerInitDate = accidentData.registerInitDate;

    road = accidentData?.road;
  }

  const dataFromBasicInformation = await loadBasicInformationSummary(
    orderParentId as string
  );

  if (stringifyForm && userData && orderParentCotic && orderParentId) {
    const sessionStorageData = JSON.parse(
      decompressSessionStorage(stringifyForm)
    );

    const combinedData: Accident = {
      orderParentCotic,
      orderParentId,
      road,
      registerInitDate,
      ...loaderToResolve,
      ...(dataFromBasicInformation as object),
      ...sessionStorageData,
    };

    return defer({
      data: combinedData,
    });
  }

  if (!stringifyForm && !stringifyAccidentForm) {
    return defer({
      data: null,
    });
  }

  return defer({
    data: {
      registerInitDate,
      road,
      ...loaderToResolve,
      dataToCompare: dataFromBasicInformation,
      ...(dataFromBasicInformation as object),
    },
  });
};
