import isEqual from 'lodash.isequal';
import transform from 'lodash.transform';
import isObject from 'lodash.isobject';

interface Vehicle {
  registVehicle?: string;
  [key: string]: any;
}

/**
 *
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object: object, base: object): object {
  function changes(object: object, base: object) {
    return transform(object, function (result: never, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return <object>changes(object, base);
}

/**
 * Deep diff between two objects, detecting added, changed, and removed elements.
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Object representing the diff
 */
export function differenceWithRemove(object: object, base: object): object {
  function changes(object: any, base: any) {
    const result = transform(base, (result: any, value, key) => {
      if (!(key in object)) {
        // Key was removed
        result[key] = isObject(value) ? '__REMOVED__' : `__REMOVED__${value}`;
      } else if (!isEqual(value, object[key])) {
        // If the value has changed, check if it's a nested object
        if (Array.isArray(value) && Array.isArray(object[key])) {
          result[key] = arrayDiff(object[key], value);
        } else {
          result[key] =
            isObject(value) && isObject(object[key])
              ? changes(object[key], value)
              : object[key];
        }
      }
    });

    // Include keys from `object` that are not in `base` (added keys)
    Object.keys(object).forEach((key) => {
      if (!(key in base)) {
        result[key] = object[key];
      }
    });

    return result;
  }

  function arrayDiff(objectArray: any[], baseArray: any[]) {
    const removed = baseArray
      .filter((item) => !objectArray.includes(item))
      .map((item) => `__REMOVED__${item}`);
    const added = objectArray.filter((item) => !baseArray.includes(item));
    return [...removed, ...added];
  }

  return changes(object, base);
}

/**
 * Split object differences into four categories:
 * 1. Differences: Contains all changes (added, modified) across all fields.
 * 2. Removed: Only `letters` and `vehicles` that were removed.
 * 3. Added: Only new `letters` and `vehicles` that were added.
 * 4. Modified: Only `letters` and `vehicles` that were modified.
 */
export function splitDifference(object: object, base: object) {
  const differences: any = {};
  const removed: any = { letters: [], vehicles: {}, registVehicle: [] };
  const added: any = { letters: [], vehicles: {} };
  const modified: any = { letters: [], vehicles: {} };

  function processDifferences(object: any, base: any, result: any) {
    return transform(base, (res: any, value, key) => {
      if (!(key in object)) {
        // Removed elements for `letters` and `vehicles`
        if (key === 'letters') {
          removed.letters.push(...base[key]);
        } else if (key === 'vehicles') {
          Object.entries(base[key] as Record<string, Vehicle>).forEach(
            ([vehicleKey, vehicleValue]) => {
              removed.vehicles[vehicleKey] = vehicleValue;
              if (vehicleValue.registVehicle) {
                removed.registVehicle.push(vehicleValue.registVehicle);
              }
            }
          );
        } else {
          // Include other changes in `differences`
          result[key] = value;
        }
      } else if (!isEqual(value, object[key])) {
        // Modified or nested differences
        if (key === 'letters') {
          const { addedArray, removedArray, modifiedArray } = arrayDiff(
            object[key],
            base[key]
          );
          added.letters.push(...addedArray);
          removed.letters.push(...removedArray);
          modified.letters.push(...modifiedArray);
          result[key] = [...object[key]];
        } else if (key === 'vehicles') {
          Object.keys(base[key]).forEach((vehicleKey) => {
            if (!(vehicleKey in object[key])) {
              removed.vehicles[vehicleKey] = base[key][vehicleKey];
              if (base[key][vehicleKey].registVehicle) {
                removed.registVehicle.push(base[key][vehicleKey].registVehicle);
              }
            }
          });
          Object.keys(object[key]).forEach((vehicleKey) => {
            if (!(vehicleKey in base[key])) {
              added.vehicles[vehicleKey] = object[key][vehicleKey];
              result[key] = result[key] || {};
              result[key][vehicleKey] = object[key][vehicleKey];
            } else if (
              !isEqual(object[key][vehicleKey], base[key][vehicleKey])
            ) {
              modified.vehicles[vehicleKey] = object[key][vehicleKey];
              result[key] = result[key] || {};
              result[key][vehicleKey] = object[key][vehicleKey];
            }
          });
        } else if (isObject(value) && isObject(object[key])) {
          result[key] = processDifferences(
            object[key],
            value,
            result[key] || {}
          );
        } else {
          result[key] = object[key];
        }
      }
    });
  }

  function arrayDiff(objectArray: any[], baseArray: any[]) {
    const addedArray = objectArray.filter((item) => !baseArray.includes(item));
    const removedArray = baseArray.filter(
      (item) => !objectArray.includes(item)
    );
    const modifiedArray = objectArray.filter((item) =>
      baseArray.includes(item)
    );
    return { addedArray, removedArray, modifiedArray };
  }

  processDifferences(object, base, differences);

  // Add fields in `object` not in `base` to `differences`
  Object.keys(object).forEach((key) => {
    if (!(key in base)) {
      if (key === 'letters') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        differences[key] = [...(differences[key] || []), ...object[key]];
      } else if (key === 'vehicles') {
        differences[key] = differences[key] || {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.entries(object[key]).forEach(([vehicleKey, vehicleValue]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!(vehicleKey in base[key] || {})) {
            differences[key][vehicleKey] = vehicleValue;
          }
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        differences[key] = object[key];
      }
    }
  });

  return { differences, removed, added, modified };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeArraysAtFirstLevel(obj: any) {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      delete obj[key];
    }
  }
  return obj;
}
