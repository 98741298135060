import { defer, LoaderFunction } from 'react-router-dom';

// Utils
import { AuthContextType } from '../../../../../hooks/useAuth';
import { decompressSessionStorage } from '../../../../../utils/sessionStorage';

// Types
import { AddBasicInfoAccidentWithDynamicKeys } from './types';

// Constants
import { formVehicleNaming } from '../../../../../components/forms/AddVehicleForm/constants';
import { formAccidentNaming } from '../../../../../components/forms/AddBasicInfoAccidentForm/constants';
import { Accident } from '../../../../../types/accident';

// Sentry
import * as Sentry from '@sentry/react';

// Selectors
import { selectVehiclesAndDamagesFromAccident } from '../../../../../gql/selectors/accidents';

const loadVehiclesData = async (orderId: string) => {
  try {
    const vehiclesData = await selectVehiclesAndDamagesFromAccident(orderId);

    return vehiclesData;
  } catch (error) {
    Sentry.captureException(error);
    console.log('> Vehicles loading error:', error);
    return true;
  }
};

export const VehicleEditLoader: (
  userData: AuthContextType | null
) => LoaderFunction | undefined = (userData) => async () => {
  const stringifyForm = sessionStorage.getItem(
    formVehicleNaming.EDIT_VEHICLE_FORM
  );

  const stringifyAccidentForm = sessionStorage.getItem(
    formAccidentNaming.EDIT_ACCIDENT_FORM
  );

  if (stringifyForm && stringifyAccidentForm && userData) {
    const sessionStorageData: AddBasicInfoAccidentWithDynamicKeys = JSON.parse(
      decompressSessionStorage(stringifyForm)
    );

    const accidentData: Accident = JSON.parse(
      decompressSessionStorage(stringifyAccidentForm)
    );

    const vehiclesData = await loadVehiclesData(accidentData.orderId as string);
    const combinedData = {
      ...(vehiclesData as object),
      ...sessionStorageData,
      orderParentCotic: accidentData.cotic,
      orderParentId: accidentData.orderId,
    };

    return defer({
      data: combinedData,
    });
  }

  if (stringifyAccidentForm && userData) {
    const accidentData: Accident = JSON.parse(
      decompressSessionStorage(stringifyAccidentForm)
    );

    const vehiclesData = await loadVehiclesData(accidentData.orderId as string);

    const combinedData = {
      orderParentCotic: accidentData.cotic,
      orderParentId: accidentData.orderId,
      ...(vehiclesData as object),
      dataToCompare: vehiclesData,
    };

    return defer({
      data: combinedData,
    });
  }

  return defer({
    data: null,
  });
};
