export const fontSize = {
  XL: '3.4rem',
  L: '2rem',
  M: '1.8rem',
  S: '1.6rem',
  XS: '1.4rem',
  XXS: '1.2rem',
  XXXS: '1rem',
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
};
