// GQL
import { gql } from '@apollo/client';

export const CREATE_NEW_ACCIDENT = gql`
  mutation CreateAccident($input: NewAccident!) {
    createAccident(input: $input) {
      id
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      damages
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      laneCutting
      policeReport
      surveillanceBodyIDs
      policeReportNumber
      roadConditionDescription
      attentionAccidentIDs
      vehicleOccupantIDs
      vehicleIDs
      surveillanceBodyIDs
      mediumKnowledgeIDs
      weatherIDs
      surfaceConditionIDs
      luminosityIDs
      conditionsDrivingIDs
      orderIDs
    }
  }
`;

export const CREATE_NEW_ATTENTION_ACCIDENT = gql`
  mutation CreateAttentionAccident($input: NewAttentionAccident!) {
    createAttentionAccident(input: $input) {
      id
      noticeConservationCenterDate
      arrivalConservationCenterDate
      departureConservationCenterDate
      noticeSquadBoysDate
      arrivalSquadBoysDate
      departureSquadBoysDate
      noticeRoadTeamDate
      arrivalRoadTeamDate
      departureRoadTeamDate
      noticeControlCenterDate
      arrivalControlCenterDate
      departureControlCenterDate
      noticeAmbulanceCraneAssistanceDate
      arrivalAmbulanceCraneAssistanceDate
      departureAmbulanceCraneAssistanceDate
      otherName
      noticeOtherDate
      arrivalOtherDate
      departureOtherDate
      accidentIDs
    }
  }
`;

export const UPDATE_NEW_ATTENTION_ACCIDENT = gql`
  mutation UpdateAttentionAccident($input: UpdateAttentionAccident!) {
    updateAttentionAccident(input: $input) {
      id
      noticeConservationCenterDate
      arrivalConservationCenterDate
      departureConservationCenterDate
      noticeSquadBoysDate
      arrivalSquadBoysDate
      departureSquadBoysDate
      noticeRoadTeamDate
      arrivalRoadTeamDate
      departureRoadTeamDate
      noticeControlCenterDate
      arrivalControlCenterDate
      departureControlCenterDate
      noticeAmbulanceCraneAssistanceDate
      arrivalAmbulanceCraneAssistanceDate
      departureAmbulanceCraneAssistanceDate
      otherName
      noticeOtherDate
      arrivalOtherDate
      departureOtherDate
      accidentIDs
    }
  }
`;

export const CREATE_NEW_IMAGE = gql`
  mutation createImage($input: NewImage!) {
    createImage(input: $input) {
      id
      name
      creatingDate
      cloudinaryPublicId
      orderIDs
    }
  }
`;

export const DELETE_MANY_IMAGES = gql`
  mutation DeleteManyImages($ids: [ID!]) {
    deleteManyImagesByIds(ids: $ids) {
      id
    }
  }
`;

export const UPDATE_ACCIDENT_ORDER = gql`
  mutation UpdateAccident($input: UpdateAccident!) {
    updateAccident(input: $input) {
      id
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;

export const UPDATE_ACCIDENT_ORDER_RELATIONSHIPS = gql`
  mutation UpdateAccidentRelationships($input: UpdateAccidentRelationships!) {
    updateAccidentRelationships(input: $input) {
      id
      concessionIDs
      roadIDs
      teamIDs
      track
      direction
      margin
      lane
      pkInitKm
      pkInitMeter
      pkEndKm
      pkEndMeter
      orderIDs
    }
  }
`;
