import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      accessToken
      refreshToken
      user {
        id
        email
        name
        surName
        rolActive
        concessionIDs
        roles
        permissions
        validated
        teamIDs
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($input: NewUser!) {
    signUp(input: $input) {
      id
      email
      name
      surName
      rolActive
      concessionIDs
      internal
      isActive
    }
  }
`;

export const UPDATE_USER_STATE = gql`
  mutation UpdateUserState($input: UpdateUser!) {
    updateUser(input: $input) {
      id
      email
      name
      surName
      roles
      rolActive
      permissions
      internal
      validated
      teamIDs
      concessionIDs
      actionIDs
      vehicleOccupantDriverIDs
      isActive
    }
  }
`;

export const VALIDATE_USER = gql`
  mutation validateUser($input: ValidateUser!) {
    validateUser(input: $input) {
      validated
    }
  }
`;

export const REGENERATE_ACCESS_TOKEN = gql`
  mutation RegenerateAccessToken($refreshToken: ID!) {
    regenerateAccessToken(refreshToken: $refreshToken)
  }
`;
