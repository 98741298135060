export const spaces = {
  xxxl: 8,
  xxl: 6.4,
  xl: 4,
  l: 3.2,
  m: 2.5,
  s: 1.6,
  xs: 1.4,
  xxs: 0.8,
  xxxs: 0.5,
};

export const breakPoints = {
  portraitTablet: 76.7,
  landscapeTablet: 102.3,
  smallDesktop: 186,
};

export const percentBaseResponsive = 0.625;
