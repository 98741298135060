import React, { useLayoutEffect } from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';

// Components Styled
import { Container } from './styles';

// Types and Const
import NewWorkOrderForm from '../../../../../components/forms/NewWorkOrderForm';
import { formAccidentNaming } from '../../../../../components/forms/AddBasicInfoAccidentForm/constants';
import { WorkOrderEditAccident } from './types';
import { WorkOrder } from '../../../../../types/workOrder';

// Store
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';

export default function EditAccidentWorkOrderPage(): JSX.Element {
  const loaderData = useAsyncValue() as WorkOrderEditAccident;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    //if (!loaderData) navigate('/work-orders/accidents');
    if (loaderData.orderId) {
      dispatch(
        coticOTSelected('edit-accident', loaderData.cotic, loaderData.orderId)
      );
    }
  }, [loaderData]);

  const onFormSubmit: SubmitHandler<WorkOrder> = async () => {
    navigate('basic-info');
  };

  return (
    <Container>
      <NewWorkOrderForm
        initialData={loaderData}
        onFormSubmit={onFormSubmit}
        namingPersistForm={formAccidentNaming.EDIT_ACCIDENT_FORM}
        titleBasicInfo={'Informació bàsica parte accident'}
        titleDate={'Data Accident:'}
        textDateHour={'Hora Accident:'}
        isAccident={true}
      />
    </Container>
  );
}
