import React, { useLayoutEffect, useState } from 'react';
import { useAsyncValue, useSubmit } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

// Components Styled
import { Container } from './styles';

//Components
import { Typography } from '../../../../../components/display/Typography';

// Utils
import { createFormDataFromValues } from '../../../../../utils/forms';

// Types and Const
import NewCommunicationForm from '../../../../../components/forms/NewCommunicationForm';
import { NewCommunication } from '../../../../../types/communication';
import { formNaming } from '../../../../../components/forms/NewCommunicationForm/constants';
import { OrderType } from '../../../../../types/orders';

// Store
import { tabsType } from '../../../../../state/reducers/ui/tabs';
import { difference } from '../../../../../utils/difference';
import { coticOTSelected } from '../../../../../state/actions/ui/coticOTSelectedGeneralView';
import { tabs } from '../../../../../state/actions/ui/tabs';
import {
  selectedRowModalAcceptData,
  selectedRowModalTable,
} from '../../../../../state/actions/ui/selectRowTableModal';
import { selectedOT } from '../../../../../state/selectors/ui/modal';

export default function EditCommunicationPage(): JSX.Element {
  const loaderData = useAsyncValue() as NewCommunication;
  const submit = useSubmit();
  const dispatch = useDispatch();
  const [error, setError] = useState<null | string>(null);
  const [originalOtValue, setOriginalOtValue] = useState<string | undefined>();

  let defaultTab = 1;

  if (loaderData.parentOrderType === OrderType.Campaign) {
    defaultTab = 2;
  }

  const selector = useSelector(
    selectedOT(formNaming.EDIT_COMMUNICATION_FORM),
    isEqual
  );

  useLayoutEffect(() => {
    dispatch(tabs(tabsType.EDIT_COMMUNICATION, defaultTab));
    dispatch(
      selectedRowModalTable(formNaming.EDIT_COMMUNICATION_FORM as string, {
        capitol: loaderData.capitolName,
        subCapitol: loaderData.subCapitolName,
        cotic: loaderData.cotic,
        id: loaderData.parentOrderId,
      })
    );
    dispatch(selectedRowModalAcceptData(formNaming.EDIT_COMMUNICATION_FORM));

    setOriginalOtValue(loaderData?.parentOrderId);
  }, []);

  const onFormSubmit = async (values: NewCommunication) => {
    const diff = difference(values, loaderData);
    if (Object.entries(diff).length > 0 || originalOtValue !== selector.id) {
      const formData = createFormDataFromValues({
        ...values,
        diff: JSON.stringify(diff),
        editCommunication: 'true',
        cotic: JSON.stringify(loaderData.cotic),
        parentOrderId: loaderData.id,
      });
      submit(formData, {
        method: 'POST',
        action: 'summary',
      });
    } else {
      setError('Cal canviar almenys un camp');
    }
  };

  useLayoutEffect(() => {
    if (loaderData.id) {
      dispatch(
        coticOTSelected(
          'edit-communication',
          (loaderData.cotic as unknown as string[]).join('.'),
          loaderData.id
        )
      );
    }
  }, [loaderData]);

  return (
    <Container>
      <NewCommunicationForm
        isNecessaryTagSpecial={false}
        initialData={loaderData}
        onFormSubmit={onFormSubmit}
        namingPersistForm={formNaming.EDIT_COMMUNICATION_FORM}
        namingTabsType={tabsType.EDIT_COMMUNICATION}
      />

      {error && (
        <Typography colorText="red" size="XS">
          {error}
        </Typography>
      )}
    </Container>
  );
}
